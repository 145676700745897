import * as Sentry from '@sentry/react'
import { scrubFields } from './initialize-rollbar'

export default function initializeSentry() {
  const sentryDSN = process.env.REACT_APP_SENTRY_DSN || ''
  const ENABLE_SENTRY = !!sentryDSN
  if (ENABLE_SENTRY) {
    Sentry.init({
      dsn: sentryDSN,
      tracesSampleRate: 1.0,
      integrations: [
        Sentry.httpClientIntegration(),
        Sentry.browserTracingIntegration()
      ],
      beforeSend(event) {
        scrubFields.forEach(field => {
          if (event[field]) {
            delete event[field]
          }
        })
        return event
      }
    })
  } else {
    /* eslint-disable-next-line no-console */
    console.log('🚫 Sentry is disabled')
  }
}
